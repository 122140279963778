/*******************************************************
    Template Name    : Swap - ICO & Cryptocurrency Bitcoin Landing Page Template
    Author           : Cute Themes
    Version          : 1.0
    Created          : 2021
    File Description : Main css file of the template
*******************************************************/

/**************************************
    -- Css Table of Content
    01.Generale Style
    02.Preloader Area Style
    03.Navber Area Style
    04.Home Section Style
    05.Work Process Section Style
    06.About Section Style
    07.Services Section Style
    08.Token Section Style
    09.Roadmap Section Style
    10.Counter Section Style
    11.Team Section Style
    12.Testimonial Section Style
    13.Why Choose Us Section Style
    14.App Download Section Style
    15.Faq Section Style
    16.Partner Logo Section Style
    17.Blog Section Style
    18.Contact Section Style
    19.Footer Section Style
 *************************************/

/**************************************
 * 01. Generale Style
 **************************************/

body {
	background: #181E34;
	color: #fff;
	font-family: 'Jura', sans-serif;
	font-size: 15px;
	font-weight: normal;
	line-height: 26px;
	overflow-x: hidden;
	margin: 0 auto;
	padding: 0;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: 'Jura', sans-serif;
	font-weight: normal;
	color: #fff;
	line-height: 1.5;
}
a {
	color: #6332f0;
	text-decoration: none !important;
	-moz-transition: all 0.5s ease-in-out 0s;
	-ms-transition: all 0.5s ease-in-out 0s;
	-o-transition: all 0.5s ease-in-out 0s;
	-webkit-transition: all 0.5s ease-in-out 0s;
	transition: all 0.5s ease-in-out 0s;
}
img {
	max-width: 100%;
}
a:hover {
	color: #6332f0;
	-moz-transition: all 0.4s ease-in-out 0s;
	-ms-transition: all 0.4s ease-in-out 0s;
	-o-transition: all 0.4s ease-in-out 0s;
	-webkit-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
}
a:hover,
a:focus {
	color: #6332f0;
	text-decoration: none;
}
p {
	padding: 0;
	margin: 0;
	font-size: 15px;
	color: #fff;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0.2px;
	font-family: 'Jura', sans-serif;
}
.z-index {
	z-index: 9;
}
.position-relative {
	position: relative!important;
}
.form-control:focus {
	box-shadow: none;
	outline: 0 none;
	border-bottom: 1px solid #6332f0;
}
.button:focus {
	outline: none !important;
	box-shadow: none;
}
button:focus {
	outline: 0 none;
	box-shadow: none;
}
input:focus {
	outline: 0 none;
	box-shadow: none;
}
.button:focus {
	outline: none;
	box-shadow: none;
}

.button {
	display: inline-block;
	padding: 10px 35px;
	text-align: center;
	vertical-align: middle;
	font-size: 15px;
	text-transform: capitalize;
	letter-spacing: 0.3px;
	font-weight: 700;
	border: 0;
	border-radius: 3px;
	color: #fff;
	background: #6332f0;
	transition: all 500ms ease;
	cursor: pointer;
	position: relative;
	margin-top: 25px;
	overflow: hidden;
	white-space: nowrap;
    transition: background .5s ease;
	-webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
	-webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
}
.button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #47A0F2;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.button:hover:before, .button:focus:before, .button:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.button:hover, .button:focus, .button:active {
	color: #fff;
}

.bg-dark {
	background-color: #1D2747 !important;
}
.bg-none {
	background: none !important;
}
.color-text {
	color: #6332f0;
}
dl,
ol,
ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.pt-50 {
	padding-top: 50px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-100 {
	padding-top: 100px;
}

.pb-50 {
	padding-bottom: 50px;
}
.pb-70 {
	padding-bottom: 70px;
}
.pb-100 {
	padding-bottom: 100px;
}

.mr-15 {
	margin-right: 15px;
}
.d-table {
	width: 100%;
	height: 100%;
}

.d-table-cell {
	vertical-align: middle;
}
.bg-fixed {
	background-attachment: fixed;
}
.bg-img {
	background-size: cover;
	background-repeat: no-repeat;
}

.about-section-padding {
	padding: 80px 0;
}

.award-segment {
	display: grid;
	grid-column-gap: 80px;
	grid-row-gap: 26px;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.prize-container {
	width: 100%;
}

.winner-title {
	font-weight: 700;
	font-size: 26px;
	margin-bottom: 40px;
}
.about-content-text {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}
.about-content-text p {
	font-size: 20px;
	line-height: 34px;
}

.stat-segment {
	width: 100%;
	display: grid;
	grid-column-gap: 42px;
	grid-row-gap: 26px;
	grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.stat-container {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.stat-flex {
	display: flex;
	align-items: center;
	justify-content: center;
}
.stat-number {
	font-size: 50px;
	font-weight: 700;
	line-height: 64px;
	color: #1F9A67;
}

.stat-percent {
	margin-left: 6px;
}
.stat-title {
	margin-top: 24px;
	font-size: 30px;
	line-height: 40px;
}

.clink {
	text-decoration: none;
	color: #FFFFFF;
	transition: color 0.15s ease-out !important;
}

.clink:hover {
	color: #1F9A67;
}
.clink:visited {
	color: #FFFFFF;
}

.clink:active {
	color: #FFFFFF;
}
.section-padding {
	padding: 100px 0;
}
.section-title {
	text-align: center;
	margin-bottom: 75px;
}
.small-title {
	color: #6332f0;
	font-size: 15px;
	font-weight: 700;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: 3px;
}

.section-title h2 {
	font-weight: 700;
	text-transform: uppercase;
	font-size: 38px;
	line-height: 1.5;
	color: #fff;
}
.display-table {
	width: 100%;
	height: 100%;
	display: table;
}
.table-cell {
	display: table-cell;
	vertical-align: middle;
}
.mr-0 {
	margin: 0;
}
.mr-top-50 {
	margin-top: 50px;
}
.back-to-top {
	position: fixed;
	cursor: pointer;
	top: 0;
	right: 15px;
	color: #ffffff;
	background: #263051;
	z-index: 4;
	width: 40px;
	text-align: center;
	height: 42px;
	line-height: 42px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: .9s;
	transition: .9s;
}
.back-to-top:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: #47A0F2;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}
.back-to-top:hover:before,
.back-to-top:focus:before {
	opacity: 1;
	visibility: visible;
}
.back-to-top:hover,
.back-to-top:focus {
	color: #ffffff;
}
.back-to-top.active {
	top: 97%;
	-webkit-transform: translateY(-98%);
	transform: translateY(-98%);
	opacity: 1;
	visibility: visible;
}
.back-to-top i:last-child {
	opacity: 0;
	visibility: hidden;
	top: 60%;
}
.back-to-top:hover i:first-child,
.back-to-top:focus i:first-child {
	opacity: 0;
	top: 0;
	visibility: hidden;
}
.back-to-top:hover i:last-child,
.back-to-top:focus i:last-child {
	opacity: 1;
	visibility: visible;
	top: 50%;
}
.back-to-top i {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	font-size: 20px;
}
.v-middle {
	position: absolute;
	width: 100%;
	top: 50%;
	left: 0;
	-webkit-transform: translate(0%, -50%);
	transform: translate(0%, -50%);
}
.valign {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
/* [ Start Overlay ] */

[data-overlay-dark],
[data-overlay-light] {
	position: relative;
}
[data-overlay-dark] .container,
[data-overlay-light] .container {
	position: relative;
	z-index: 2;
}
[data-overlay-dark]:before,
[data-overlay-light]:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
}
[data-overlay-dark]:before {
	background: #02050b;
}
[data-overlay-light]:before {
	background: #fff;
}
[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark span,
.bg-color h1,
.bg-color h2,
.bg-color h3,
.bg-color h4,
.bg-color h5,
.bg-color h6,
.bg-color span {
	color: #fff;
}

[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
	opacity: 0;
}
[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
	opacity: .1;
}
[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
	opacity: .2;
}
[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
	opacity: .3;
}
[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
	opacity: .4;
}
[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
	opacity: .5;
}
[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
	opacity: .6;
}
[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
	opacity: .7;
}
[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
	opacity: .8;
}
[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before,
[data-overlay-color="9"]:before {
	opacity: .9;
}
[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
	opacity: 1;
}
/* [ End Overlay ] */

/* owl theme */

.owl-theme .owl-nav.disabled+.owl-dots {
	margin-top: 20px;
}
.owl-theme .owl-dots .owl-dot span {
	width: 7px;
	height: 7px;
	margin: 5px 5px;
	background-color: #fff;
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity .2s ease;
	border-radius: 5px;
	transition: .3s;
	border: 2px solid transparent;
}
.owl-theme .owl-dots .owl-dot.active span {
	background-color: #6332f0 !important;
	width: 7px;
	height: 7px;
	transition: .3s;
	-webkit-box-shadow: 0 1px 5px #6332f0;
	box-shadow: 0 1px 5px #6332f0;
}
.owl-theme .owl-dots .owl-dot:hover span {
	background-color: transparent;
	border: 2px solid #6332f0;
	transition: .3s;
}

/**************************************
 * 02.Preloader Area Style
 **************************************/
.preloader {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	position: fixed;
	z-index: 999999;
	height: 100%;
	width: 100%;
	background-color: #fff;
}
.spinner {
	width: 50px;
	height: 50px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	font-size: 10px;
	text-indent: -12345px;
	z-index: 10000;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #6332f0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/**************************************
 * 03.Navber Area Style
 **************************************/
.navbar-b {
	transition: all .5s ease-in-out;
	background-color: transparent;
	padding-top: 1.563rem;
	padding-bottom: 1.563rem;
}
.navbar-b.navbar-reduce {
	/*box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);*/
}
.navbar-b.navbar-trans .nav-item,
.navbar-b.navbar-reduce .nav-item {
	position: relative;
	padding-right: 10px;
	padding-left: 0;
}
.main-index-nav.navbar-b.navbar-trans .nav-link,
.main-index-nav.navbar-b.navbar-reduce .nav-link {
	color: #333;
}
.navbar-b.navbar-trans .nav-link,
.navbar-b.navbar-reduce .nav-link {
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 15px;
	letter-spacing: 0.3px;
	font-family: 'Jura', sans-serif;
}
.navbar-b.navbar-trans .nav-link:hover,
.navbar-b.navbar-reduce .nav-link:hover {
	color: #1B1B1B;
}
.navbar-b.navbar-trans .nav-link:hover {
	color: #fff;
}
.main-index-nav.navbar-b.navbar-trans .nav-link:hover {
	color: #333;
}
.main-index-nav.navbar-b.navbar-trans .show > .nav-link,
.main-index-nav.navbar-b.navbar-trans .active > .nav-link,
.main-index-nav.navbar-b.navbar-trans .nav-link.show,
.main-index-nav.navbar-b.navbar-trans .nav-link.active {
	color: #333;
}
.navbar-b.navbar-trans .show > .nav-link,
.navbar-b.navbar-trans .active > .nav-link,
.navbar-b.navbar-trans .nav-link.show,
.navbar-b.navbar-trans .nav-link.active {
	color: #fff;
}
.navbar-b.navbar-reduce {
	transition: all .5s ease-in-out;
	/*background-color: #fff;*/
	padding-top: 20px;
	padding-bottom: 20px;
	-webkit-animation: 500ms ease-in-out 0s normal fadeInDown;
	animation: 500ms ease-in-out 0s normal fadeInDown;
	-webkit-transition: 0.6s;
	transition: 0.6s;
}
.navbar-b.navbar-reduce .nav-link {
	/*color: #555;*/
}
.navbar-b.navbar-reduce .nav-link:hover {
	/*color: #505050;*/
}
.main-index-nav.navbar-b.navbar-reduce .show > .nav-link,
.main-index-nav.navbar-b.navbar-reduce .active > .nav-link,
.main-index-nav.navbar-b.navbar-reduce .nav-link.show,
.main-index-nav.navbar-b.navbar-reduce .nav-link.active {
	/*color: #333;*/
}
.navbar-b.navbar-reduce .show > .nav-link,
.navbar-b.navbar-reduce .active > .nav-link,
.navbar-b.navbar-reduce .nav-link.show,
.navbar-b.navbar-reduce .nav-link.active {
	/*color: #555;*/
}
.navbar-b.navbar-reduce .navbar-brand {
	/*color: #333;*/
}
/*.navbar-b.navbar-reduce .navbar-toggler span {*/
/*	background-color: #1B1B1B;*/
/*}*/
.navbar-b .navbar-brand {
	color: #fff;
	font-size: 1.6rem;
	font-weight: 700;
}
.navbar-b .navbar-nav .dropdown-item.show .dropdown-menu,
.navbar-b .dropdown.show .dropdown-menu,
.navbar-b .dropdown-btn.show .dropdown-menu {
	-webkit-transform: translate3d(0px, 0px, 0px);
	transform: translate3d(0px, 0px, 0px);
	visibility: visible !important;
}
.navbar-b .dropdown-menu {
	margin: 1.12rem 0 0;
	border-radius: 0;
}
.navbar-b .dropdown-menu .dropdown-item {
	padding: .7rem 1.7rem;
	transition: all 500ms ease;
}
.navbar-b .dropdown-menu .dropdown-item:hover {
	background-color: #6332f0;
	color: #fff;
	transition: all 500ms ease;
}
.navbar-b .dropdown-menu .dropdown-item.active {
	background-color: #6332f0;
}
.navbar-toggler {
	position: relative;
}
.navbar-toggler:focus,
.navbar-toggler:active {
	outline: 0;
}
.navbar-toggler span {
	display: block;
	background-color: #fff;
	height: 3px;
	width: 25px;
	margin-top: 4px;
	margin-bottom: 4px;
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	left: 0;
	opacity: 1;
}
.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
	transition: -webkit-transform .35s ease-in-out;
	transition: transform .35s ease-in-out;
	transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
	position: absolute;
	left: 12px;
	top: 10px;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	opacity: 0.9;
}
.navbar-toggler:not(.collapsed) span:nth-child(2) {
	height: 12px;
	visibility: hidden;
	background-color: transparent;
}
.navbar-toggler:not(.collapsed) span:nth-child(3) {
	position: absolute;
	left: 12px;
	top: 10px;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
	opacity: 0.9;
}
.navbar-b .black-logo {
	display: none;
}
/*.navbar-b.navbar-reduce .navbar-brand .white-logo {*/
/*	display: none;*/
/*}*/
/*.navbar-b.navbar-reduce .navbar-brand .black-logo {*/
/*	display: block;*/
/*}*/

/**************************************
 * 04.Home Section Style
 **************************************/
.home-area {
	height: 100vh;
	position: relative;
	color: #fff;
	text-align: center;
	z-index: 1;
	padding-top: 130px;
	padding-bottom: 40px;
	background: #181E34;
}


.hero-equal-height {
	position: relative;
	min-height: 660px;
	-js-display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
	width: 100%;
}
.home-area .home-content {
	text-align: left;
	padding-top: 100px;
}
.home-area .home-content h1 {
	font-size: 45px;
	color: #ffffff;
	margin-bottom: 10px;
	font-weight: 700;
	line-height: 1.5;
}
.home-area .home-content .custom-font {
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
}
.home-area .home-content p {
	font-size: 16px;
	color: #ffffff;
}

.home-content .home-subcribe-box {
    margin-top: 30px;
}

.home-content .home-subcribe-box .input-box input {
    width: 60%;
    height: 53px;
    background: #fff;
    border-radius: 3px;
    color: #333;
    font-size: 15px;
    font-weight: 600;
    border: 0;
    display: inline-block;
    padding-left: 20px;
    margin-right: 10px;
}

.home-content .home-subcribe-box .input-box .subcribe-btn {
    width: 220px;
    border: 0;
    height: 53px;
    margin: 0;
    background: #1F9A67;
    color: #FFFFFF;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    border-radius: 8px;
    transition: all 0.3s ease-out;
}

.subcribe-btn:hover {
  background-color: #1D7845 !important;
}

.subcribe-btn:active {
  background-color: #0C6734 !important;
}
::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #333;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: #333;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: #333;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: #333;
}

#particles-js {
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 3;
}

/**************************************
 * 05.Work Process Section Style
 **************************************/
.work-process-content {
    position: relative;
	z-index: 1;
    padding: 0px 15px;
	text-align: center;
	margin-top: 30px;
}
.work-process-content img {
	margin: auto;
	margin-bottom: 15px;
	width: 90px;
}
.work-process-content h3 {
	color: #fff;
    font-size: 20px;
    font-weight: 600;
	margin-bottom: 15px;
}

/**************************************
 * 06.About Section Style
 **************************************/
.about-area {
	position: relative;
	z-index: 1;
}

.about-content h2 {
	font-size: 33px;
	font-weight: 700;
	margin-bottom: 15px;
}

.about-content-text p {
	margin-bottom: 15px;
}
.about-content-text .button {
	margin-top: 10px;
}

.about-list {
	margin-bottom: 20px;
}

.about-list li {
	position: relative;
	line-height: 45px;
}

.about-list li i {
    color: #ffffff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: rgb(255, 255, 255, 0.15);
    border-radius: 50%;
    margin-right: 10px;
	box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.1);
}

.about-image {
	position: relative;
	text-align: center;
}
.about-image img {
	border-radius: 3px;
}

/**************************************
 * 07.Services Section Style
 **************************************/
.services-area .section-title {
	margin-bottom: 45px;
}

.single-services-item {
	position: relative;
	z-index: 1;
	display: block;
	padding: 50px 30px;
	text-align: center;
	border-radius: 3px;
    background-color: #3d1d9a;
	margin-top: 35px;
    -webkit-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
}
.single-services-item::after {
	content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: .7;
    background: url(../img/services/services-bg.svg);
    background-repeat: no-repeat;
    background-position: top right;
}
.services-images {
	margin-bottom: 20px;
}

.services-images img {
    width: 85px;
}

.single-services-item h3 {
	font-size: 18px;
    font-weight: 700;
	margin-bottom: 0;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    will-change: transform;
}

.single-services-item h3 a {
	display: inline-block;
}

.single-services-item p {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 0;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    will-change: transform;
}

/**************************************
 * 08.Token Section Style
 **************************************/
.token-content h2 {
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 15px;
}

.token-content p {
    margin-bottom: 15px;
}

.token-content .token-list li {
	font-weight: 500;
    margin-bottom: 20px;
}

.token-content .token-list li:last-child {
    margin-bottom: 0px;
}

.token-content .token-list li span {
    font-size: 16px;
    font-weight: 600;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    display: inline-block;
    border-radius: 0;
    margin-right: 10px;
}
.token-content .token-list li .color-1 {
    background: #0fa5fd;
}
.token-content .token-list li .color-2 {
    background: #865bf3;
}
.token-content .token-list li .color-3 {
    background: #e65356;
}
.token-content .token-list li .color-4 {
    background: #e6c253;
}
.token-content .token-list li .color-5 {
    background: #00f9a7;
}
.token-image {
	text-align: center;
}

/**************************************
 * 09.Roadmap Section Style
 **************************************/
.roadmap-wrapper {
	overflow: hidden;
}

.h-border {
	width: 100%;
	height: 4px;
	background: #6332f0;
	margin-top: 181px;
}

.roadmap-single-box {
	text-align: center;
	position: relative;
	margin-top: -12px;
}

.small-round {
	background: #6332f0;
	border: 3px solid #fff;
	-webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
	height: 20px;
	width: 20px;
	position: relative;
	left: 50%;
	margin-left: -10px;
	z-index: 1;
}

.date-title {
	position: absolute;
	bottom: 100%;
	width: 100%;
	color: #fff;
	font-size: 15px;
	padding: 12px 0;
	font-weight: 600;
}

.roadmap-slider .main-box .roadmap-chart {
	overflow: hidden;
}

.v-row {
	background: #6332f0;
	height: 50px;
	width: 4px;
	position: relative;
	left: 50%;
	margin-left: -2px;
	overflow: hidden;
}
.roadmap-slider .owl-item .main-box .roadmap-detail-box p {
	font-size: 16px;
	margin-bottom: 0px;
}

.roadmap-slider .owl-item .main-box .roadmap-detail-box h3 {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.roadmap-slider .owl-item:nth-child(2n+0) .main-box .roadmap-detail-box {
	position: absolute;
	bottom: 100%;
	margin-top: 0;
}

.roadmap-slider .owl-item:nth-child(2n+0) .main-box .roadmap-detail-box p {
	margin-bottom: 10px;
}

.roadmap-slider .owl-item:nth-child(2n+0) .main-box .roadmap-single-box {
	padding-top: 50px;
	margin-top: -62px;
}

.roadmap-slider .owl-item:nth-child(2n+0) .main-box .date-title {
	top: 100%;
}

.roadmap-slider .owl-item:nth-child(2n+0) .main-box .roadmap-chart {
	transform: rotate(180deg);
	margin-top: -50px;
	display: inline-block;
}

.roadmap-slider .owl-item:nth-child(2n+0) .main-box .v-row {
	margin-left: -1px;
}
.roadmap-slider .owl-item {
	float: left;
}
.roadmap-slider .item {
    text-align: center;
}
.roadmap-slider .owl-nav {
    display: none;
}
.roadmap-slider .item {
	padding: 0 15px;
}

/**************************************
 * 10.Counter Section Style
 **************************************/
.counter-area {
	position: relative;
    text-align: center;
}

.counter-contents h2 {
	color: #fff;
	font-size: 45px;
	font-weight: 700;
	margin-bottom: 5px;
	word-spacing: -10px;
}
.counter-contents h3 {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
}

/**************************************
 * 11.Team Section Style
 **************************************/
.team-area {
}

.team-area .section-title {
	margin-bottom: 45px;
}
.single-team-box {

    margin-top: 30px;
    padding: 35px 20px 25px;
	border-radius: 5px;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background: #3d1d9a;

}
.team-image {
    position: relative;
    z-index: 1;
    border-radius: 50%;
    overflow: hidden;
    width: 160px;
    height: 160px;
    margin: 0 auto;
	box-shadow: 0 0 0 6px rgb(255, 255, 255, 0.15);
}

.team-image::before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    background: #000;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}
.single-team-box:hover .team-image::before {
    opacity: .5;
    visibility: visible;
}

.single-team-box .team-image img {
	width: 100%;
	height: auto;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.single-team-box:hover .team-image img {
	-webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

.team-social-icon {
	text-align: center;
}

.team-social-icon a {
    padding: 5px 10px;
    color: #fff;
	font-size: 18px;
	display: inline-block;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.team-social-icon a i {
    color: #fff;
	font-size: 18px;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.team-social-icon a:hover i {
    opacity: 0.3;
}

.team-info {
	padding: 20px 15px 10px;
	text-align: center;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}
.team-info h3 {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 0;
}
.team-info span {
	color: #ff9b08;
	font-size: 15px;
	font-weight: 500;
	display: block;
}

/**************************************
 * 12.Testimonial Section Style
 **************************************/
.testimonial-area {
	position: relative;
	z-index: 1;
    background: url(../img/testimonial-bg.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.testimonial-area .owl-stage-outer {
  margin: 0 -8px;
  padding-left: 8px;
  padding-top: .35rem;
}
.testimonial-content-inner .testimonial-text {
	position: relative;
    padding: 10px 25px;
    text-align: center;
    border-radius: 3px;
    background: transparent;
}

.testimonial-content-inner .testimonial-text p {
	font-size: 16px;
}

.testimonial-content-inner .testimonial-text .rating-box {
	margin-bottom: 15px;
}
.rating-box ul li {
	display: inline-block;
	margin-right: 3px;
}

.rating-box ul li i {
	color: #ffce39;
	font-size: 18px;
}
.testimonial-content-inner .author-info-box {
    text-align: center;
	margin-bottom: 15px;
}
.testimonial-content-inner .author-info-box .author-img img {
    width: 85px;
    margin: 0 auto;
    border-radius: 50%;
}
.testimonial-content-inner .author-info-box .author-img {
	margin-bottom: 18px;
}
.testimonial-content-inner .author-info-box h3 {
	font-size: 18px;
    font-weight: 700;
}

.testimonial-content-inner .author-info-box span {
	font-size: 15px;
    font-weight: 500;
}

/**************************************
 * 13.Why Choose Us Section Style
 **************************************/
.choose-area .section-title {
    margin-bottom: 40px;
}
.choose-single-content {
    position: relative;
	z-index: 1;
    padding: 0px 15px;
	text-align: center;
	margin-top: 35px;
}
.choose-single-content img {
	margin: auto;
	margin-bottom: 15px;
	width: 70px;
}
.choose-single-content h3 {
	color: #fff;
    font-size: 18px;
    font-weight: 700;
	margin-bottom: 15px;
}

/**************************************
 * 14.App Download Section Style
 **************************************/
.download-content h5 {
    color: #ff9b08;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.download-content h2 {
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 20px;
}

.download-list {
	margin-top: 20px;
}

.download-list li {
	line-height: 45px;
}

.download-list li i {
    color: #ffffff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: rgb(255, 255, 255, 0.15);
    border-radius: 50%;
    margin-right: 10px;
	box-shadow: 0 0 0 3px rgb(0, 0, 0, 0.1);
}

.download-content .download-btn {
	margin-top: 25px;
}
.download-content .download-btn .app-link-btn-1 {
	display: inline-block;
	margin-top: 0px;
	margin-right: 10px;
}

.download-content .download-btn .icon-btn {
	display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
    padding-top: 6px;
}

.download-content .download-btn .icon-btn i {
	color: #fff;
    font-size: 18px;
}

.download-content .download-btn .icon-btn:hover {
	opacity: .8;
}
.download-image {
    text-align: center;
}

/**************************************
 * 15.Faq Section Style
 **************************************/
.faq-area {
}

.faq-accordion .accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 0;
  margin-bottom: 0;
  position: relative;
}

.faq-accordion .accordion .accordion-item {
	display: block;
    border-radius: 3px;
	margin-bottom: 20px;
    overflow: hidden;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-title {
    padding: 20px;
    color: #fff;
    position: relative;
    background: #3d1d9a;
    font-size: 18px;
    font-weight: 700;
    display: block;
    border: none;
    padding-right: 50px;
}

.faq-accordion .accordion .accordion-item .accordion-title i {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    font-size: 15px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.faq-accordion .accordion .accordion-item .accordion-title.active i::before {
  content: "\f068";
}

.faq-accordion .accordion .accordion-item .accordion-content {
	display: none;
	font-size: 15px;
    background-color: #3d1d9a;
    margin-bottom: 0;
    padding: 20px 15px;
    border-top: 1px solid rgb(255, 255, 255, 0.3);
}

.faq-accordion .accordion .accordion-item .accordion-content.show {
	display: block;
}

/**************************************
 * 16.Partner Logo Section Style
 **************************************/
.partner-area  {
    padding: 70px 0;
}
.partner-area .section-title {
    margin-bottom: 50px;
}
.partner-item {
    padding: 5px 20px;
    margin: 5px 15px;
}
.partner-area .partner-carousel img {
	max-width: 100%;
	width: inherit!important;
	margin: 0 auto;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.partner-area .partner-carousel img:hover {
	opacity: .6;
	cursor: pointer;
}

/**************************************
 * 17.Blog Section Style
 **************************************/
.blog-area {
}
.blog-area .section-title {
	text-align: center;
    margin-bottom: 45px;
}

.blog-area .single-blog {
    margin-top: 35px;
    overflow: hidden;
    border-radius: 5px;
    background: #3d1d9a;
}

.blog-area .post-img {
	position: relative;
	overflow: hidden;
}

.blog-area .single-blog img {
	-webkit-transition: all .5s;
	transition: all .5s;
}

.blog-area .single-blog:hover img {
	-webkit-filter: none;
	filter: none;
	-webkit-transform: scale(1.05, 1.05);
	-ms-transform: scale(1.05, 1.05);
	transform: scale(1.05, 1.05);
}

.blog-area .single-blog .blog-content {
	padding: 30px 25px;
	text-align: left;
}

.blog-content .blog-date {
	margin-bottom: 15px;
}

.blog-content .blog-date .blog-list li {
	display: inline-block;
}

.blog-content .blog-date .blog-list li h6 {
	font-size: 13px;
	color: #ddd;
	font-weight: 600;
	display: inline-block;
}

.blog-content .blog-date .blog-list li .blog-inline-sep {
	padding: 0 5px;
	font-size: 12px;
	color: #ddd;
}

.blog-content .blog-date .blog-list li a {
	font-size: 13px;
	color: #ddd;
	font-weight: 600;
	display: inline-block;
}

.blog-content .blog-date .blog-list li a:hover {
	color: #ff9b08;
}

.blog-area .single-blog .blog-content h3 a {
	font-size: 18px;
	font-weight: 700;
	color: #fff;
	line-height: 1.5;
	margin-bottom: 10px;
	display: block;
}

.blog-area .single-blog .blog-content h3 a:hover {
	color: #ff9b08;
}

/**************************************
 * 18. Contact Section Style
 **************************************/
.contact-area .section-title {
    margin-bottom: 25px;
}

.contact-details {
    display: block;
    text-align: center;
    margin-top: 50px;
	margin-left: 16px;
	margin-right: 16px;
}
.contact-details img {
    width: 70px;
    margin-bottom: 25px;
}

.contact-details h6 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.contact-details p a {
    color: #fff;
    border-bottom: 1px solid white;
}

/**************************************
 * 19.Footer Section Style
 *************************************/
.footer-area {
	padding: 30px 0;
	background-color: #0f1017;
}
.footer-copyright-text p {
  text-align: center;
	color: #fff;
}
.footer-area .footer-nav-link ul {
    text-align: right;
    list-style-type: none;
}
.footer-area .footer-nav-link ul li {
    display: inline-block;
    position: relative;
    padding-left: 20px;
}
.footer-area .footer-nav-link ul li a {
    color: #ffffff;
}

.footer-area .footer-nav-link ul li:first-child {
    padding-left: 0;
}
.footer-area .footer-nav-link ul li::before {
    content: '';
    position: absolute;
    left: 7px;
    top: 5px;
    background: #cccccc;
    width: 2px;
    height: 14px;
}
.footer-area ul li:first-child::before {
  display: none;
}
